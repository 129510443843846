import { Pipe, PipeTransform } from '@angular/core';
import { FleetIdentifierPipe } from '../../../../common/pipes/fleet-identifier.pipe';
import { FleetCategory } from '../../../fleet/common/enums/fleet-category';
import { FleetHelper } from '../../../fleet/common/helpers/fleet.helper';
import { TelematicsFleet } from '../interfaces/telematics-fleet';

@Pipe({
  name: 'mapFleetIdentifier',
  pure: true,
})
export class MapFleetIdentifierPipe implements PipeTransform {
  constructor(private fleetIdentifierPipe: FleetIdentifierPipe) {}

  public transform(
    vehicle: TelematicsFleet,
    format: 'short' | 'long' = 'short',
  ): string {
    const vehicleData = FleetHelper.isVehicle(vehicle)
      ? vehicle
      : vehicle.assignedVehicle;
    const trailerData = FleetHelper.isTrailer(vehicle)
      ? vehicle
      : vehicle.assignedTrailer;

    const pipeFormat = format === 'short' ? 'registrationNumber' : 'dot';
    const vehicleIdentifier = this.fleetIdentifierPipe.transform(
      vehicleData,
      pipeFormat,
    );
    const trailerIdentifier = this.fleetIdentifierPipe.transform(
      trailerData,
      pipeFormat,
    );

    const category = vehicle.generalInformation.category;
    if (trailerData || category === FleetCategory.SEMI_TRUCK) {
      return `${vehicleIdentifier} / ${trailerIdentifier}`;
    }
    return vehicleIdentifier;
  }
}
